<template>
  <div>
    <el-table
      :data="deptBelowData"
      size="medium"
      row-key="id"
      border
      height="calc(100vh - 293px)"
      :tree-props="{children: 'children', hasChildren: 'title'}"
      default-expand-all>
      <el-table-column
        prop="deptName"
        label="组织名称">
      </el-table-column>
      <el-table-column
        prop="institutionType"
        label="类型">
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="120">
        <template slot-scope="scope">
          <el-button
            @click="chooseDeptBelow(scope.row)"
            type="text"
            size="small">
            确定
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import {deptChildTreeItem} from "@/api/reportTemplate";

export default {
  data() {
    return {
      deptBelowData:[]
    }
  },
  mounted() {
    deptChildTreeItem().then((res) =>{
      if (res.data.code == 200) {
        this.deptBelowData.push(res.data.data);
      }
    })
  },
  methods:{
    chooseDeptBelow(row) {
      this.$emit('getDeptBelow',row);
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
